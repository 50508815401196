// src/components/DeviceRegistration.js
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, updateDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

const DeviceRegistration = ({ onDeviceRegistered }) => {
  const [deviceCode, setDeviceCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false); // Added success state
  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      // Query for the device code
      const deviceCodesRef = collection(db, 'device_codes');
      const q = query(
        deviceCodesRef, 
        where('code', '==', deviceCode),
        where('claimed', '==', false)
      );
      
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        setError('Invalid or already claimed device code');
        return;
      }

      // Get the device code document
      const deviceCodeDoc = querySnapshot.docs[0];
      const deviceCodeData = deviceCodeDoc.data();

      // 1. Create new device document
      const devicesRef = collection(db, 'devices');
      const newDevice = {
        deviceId: deviceCodeData.deviceId,
        userId: user.uid,
        name: `New Device ${deviceCodeData.deviceId}`,
        status: 'inactive',
        createdAt: serverTimestamp(),
        lastConnected: null
      };

      const deviceDoc = await addDoc(devicesRef, newDevice);

      // 2. Update device code to mark as claimed
      await updateDoc(doc(deviceCodesRef, deviceCodeDoc.id), {
        claimed: true,
        claimedBy: user.uid,
        claimedAt: serverTimestamp()
      });

      setDeviceCode('');
      setSuccess(true); // Show success message
      onDeviceRegistered?.({
        id: deviceDoc.id,
        ...newDevice
      });

    } catch (error) {
      console.error('Error registering device:', error);
      setError('Failed to register device. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h2 className="text-lg font-semibold mb-4">Register New Device</h2>
      
      {error && (
        <div className="bg-red-50 text-red-600 p-3 rounded mb-4">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-50 text-green-600 p-3 rounded mb-4">
          Device registered successfully!
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Device Registration Code
          </label>
          <input
            type="text"
            value={deviceCode}
            onChange={(e) => setDeviceCode(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            placeholder="Enter device code"
            required
            disabled={isLoading}
          />
        </div>

        <button
          type="submit"
          disabled={isLoading || !deviceCode}
          className={`w-full py-2 px-4 rounded-md text-white
            ${isLoading 
              ? 'bg-blue-400 cursor-not-allowed' 
              : 'bg-blue-600 hover:bg-blue-700'
            }`}
        >
          {isLoading ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Registering...
            </span>
          ) : (
            'Register Device'
          )}
        </button>
      </form>
    </div>
  );
};

export default DeviceRegistration;