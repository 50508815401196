// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'; // Add this import

const firebaseConfig = {
  apiKey: "AIzaSyAE3XQVMwSdWIx0iM6RJsT_ETNO5zJAIn0",
  authDomain: "aliberta-app.firebaseapp.com",
  projectId: "aliberta-app",
  storageBucket: "aliberta-app.appspot.com",
  messagingSenderId: "1066322375137",
  appId: "1:1066322375137:web:377cd72b1b6d49cc3a62a0",
  measurementId: "G-YV940CFPDN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app); // Add this line

export { auth, analytics, db };