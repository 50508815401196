import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  // Handle token refresh
  useEffect(() => {
    let unsubscribeToken = () => {};

    if (user) {
      // Set up token refresh interval
      const tokenRefreshInterval = setInterval(async () => {
        try {
          const newToken = await user.getIdToken(true);
          setToken(newToken);
        } catch (error) {
          console.error('Token refresh error:', error);
        }
      }, 45 * 60 * 1000); // Refresh every 45 minutes (tokens expire after 1 hour)

      // Get initial token
      user.getIdToken(true)
        .then(newToken => setToken(newToken))
        .catch(error => console.error('Initial token fetch error:', error));

      // Set up token change listener
      unsubscribeToken = auth.onIdTokenChanged(async (user) => {
        if (user) {
          try {
            const newToken = await user.getIdToken();
            setToken(newToken);
          } catch (error) {
            console.error('Token change error:', error);
          }
        } else {
          setToken(null);
        }
      });

      return () => {
        clearInterval(tokenRefreshInterval);
        unsubscribeToken();
      };
    }
  }, [user]);

  // Handle auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Function to manually refresh token if needed
  const refreshToken = async () => {
    if (user) {
      try {
        const newToken = await user.getIdToken(true);
        setToken(newToken);
        return newToken;
      } catch (error) {
        console.error('Manual token refresh error:', error);
        throw error;
      }
    }
    return null;
  };

  const value = {
    user,
    loading,
    token,
    refreshToken
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};