import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Lock, Settings, Globe, Home, MenuIcon, Shield, Plus, 
  RefreshCcw, AlertCircle, Bug, Trash, RotateCcw, BellOff, 
  Battery, Signal, SendHorizontal, LogOut 
} from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './components/ui/card';
import { Button } from './components/ui/button';
import { Alert, AlertTitle, AlertDescription } from './components/ui/alert';
import Sidebar from './components/Sidebar';
import { useAuth } from './contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import DeviceRegistration from './components/DeviceRegistration';

const CONFIG = {
    WS_HOST: process.env.REACT_APP_WS_HOST || 'cluster01.aliberta-alarmanlagen.at',
    WS_PORT: process.env.REACT_APP_WS_PORT || 9443
};


const SYSTEM_COMMANDS = {
    ARM: "!n0s2&",
    PARTIAL_ARM: "!n0s1&",
    DISARM: "!n0s0&",
    CLEAR_STATUS: "!n0s8&",
    GET_ZONES: "!sr001&",
    GET_BATTERY: "!ba&",
    GET_SIGNAL: "!rs&"
};

const Dashboard = () => {
    const navigate = useNavigate();
    const { user: authStateUser, token, loading } = useAuth();
    const webSocket = useRef(null);
	const retryCount = useRef(0);

	useEffect(() => {
        retryCount.current = 0;
		}, [token]);

    // State declarations - moved to top level
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [language, setLanguage] = useState('de');
	const [systemStatus, setSystemStatus] = useState('disarmed');
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);
    const [accessories, setAccessories] = useState([]);
    const [deviceState, setDeviceState] = useState('UNKNOWN');
    const [commandQueue, setCommandQueue] = useState([]);
    const [isProcessingCommand, setIsProcessingCommand] = useState(false);
    const [debugMode, setDebugMode] = useState(false);
    const [debugMessages, setDebugMessages] = useState([]);
    const [events, setEvents] = useState([]);
    const [batteryStatus, setBatteryStatus] = useState({});
    const [signalStrength, setSignalStrength] = useState({});
    const [debugCommand, setDebugCommand] = useState('');

	const translations = {
    de: {
        title: 'Aliberta App',
        modules: 'Module',
        accessories: 'Zubehör',
        addAccessory: 'Zubehör hinzufügen',
        settings: 'Einstellungen',
        status: {
            armed: 'Scharf',
            partiallyArmed: 'Teilscharf',
            disarmed: 'Unscharf',
            clear: 'Status zurücksetzen'
        },
        debug: {
            title: 'Debug Konsole',
            clear: 'Leeren',
            show: 'Debug anzeigen',
            hide: 'Debug ausblenden'
        },
        connection: {
            connected: 'Verbunden',
            disconnected: 'Getrennt'
        },
        auth: {
            logout: 'Abmelden'
        },
        events: {
            title: 'Ereignisse',
            fireAlarm: 'Feueralarm',
            burglaryAlarm: 'Einbruchalarm',
            panicAlarm: 'Panikalarm',
            systemTrouble: 'Systemfehler',
            lowBattery: 'Batterie schwach'
        },
        devices: {
            status: {
                online: 'Online',
                offline: 'Offline',
                triggered: 'Ausgelöst',
                tampered: 'Manipulation'
            },
            types: {
                button: 'Taste',
                contact: 'Kontakt',
                emergency: 'Notfall',
                fire: 'Feuer',
                motion: 'Bewegung'
            }
        }
    },
    en: {} // Add English translations if needed
	 };
    const t = translations[language];

const getSignalStrengthDots = useCallback((strength) => {
    const dots = [0, 1, 2, 3].map((i) => (
        <div 
            key={i}
            className={`w-1 h-3 rounded-sm ${Number(i) < Number(strength) ? 'bg-green-500' : 'bg-gray-300'}`}
        />
    ));

    return (
        <div className="flex space-x-0.5">
            {dots}
        </div>
    );
}, []); // Empty dependency array since this function doesn't depend on any props or state

// Core utility callbacks - optimized for production build
const addDebugMessage = useCallback((message, type = 'info') => {
    setDebugMessages(prev => [{
        timestamp: new Date().toISOString(),
        message,
        type
    }, ...prev].slice(0, 50));
}, []); // No dependencies needed

const getStatusColor = useCallback((status) => {
    switch (status?.toLowerCase()) {
        case 'offen':
        case 'triggered':
            return 'text-red-500';
        case 'geschlossen':
        case 'online':
            return 'text-green-500';
        case 'tampered':
            return 'text-orange-500';
        default:
            return 'text-gray-500';
    }
}, []); // No dependencies needed since it's a pure function

const handleError = useCallback((errorMessage) => {
    setError(`Device error: ${errorMessage}`);
    if (debugMode) {
        addDebugMessage(`Device error: ${errorMessage}`, 'error');
    }
}, [debugMode, addDebugMessage]);


// Rename the useCallback hook for queue handling
const queueCommand = useCallback((command) => {
    if (webSocket.current?.readyState === WebSocket.OPEN) {
        setCommandQueue(prev => [...prev, command]);
        if (debugMode) {
            addDebugMessage(`Queued command: ${command}`, 'info');
        }
    } else {
        setError('WebSocket is not connected');
        if (debugMode) {
            addDebugMessage('Failed to queue command - WebSocket not connected', 'error');
        }
    }
}, [debugMode, addDebugMessage]);

    // Event handlers
    const handleEventInfo = useCallback((eventInfo) => {
        setEvents(prev => [{
            id: Math.random().toString(36).substr(2, 9),
            timestamp: new Date().toISOString(),
            ...eventInfo
        }, ...prev].slice(0, 100));

        if (['1100', '1110', '1120', '1130'].includes(eventInfo.eventCode)) {
            setError(t.events[eventInfo.eventDescription.toLowerCase().replace(' ', '')] || eventInfo.eventDescription);
        }
    }, [t.events]);

    const handleNewDevice = useCallback((deviceInfo) => {
        setAccessories(prev => {
            const existingIndex = prev.findIndex(acc => acc.id === deviceInfo.id);
            if (existingIndex >= 0) {
                const updated = [...prev];
                updated[existingIndex] = {
                    ...updated[existingIndex],
                    type: deviceInfo.type,
                    timestamp: deviceInfo.timestamp
                };
                return updated;
            }
            return [...prev, {
                id: deviceInfo.id,
                name: `New ${deviceInfo.type} Device`,
                type: deviceInfo.type,
                status: 'Unknown',
                timestamp: deviceInfo.timestamp,
                signalStrength: 4,
                isHome: true,
                groupId: deviceInfo.zone.toString().padStart(2, '0')
            }];
        });
    }, []);

    const handleBatteryStatus = useCallback((data) => {
        const match = data.match(/!ba&([0-9a-f]{2})([0-9a-f]{2})/);
        if (match) {
            const [, zoneId, level] = match;
            setBatteryStatus(prev => ({
                ...prev,
                [zoneId]: parseInt(level, 16)
            }));
        }
    }, []);

    const handleSignalStrength = useCallback((data) => {
        const match = data.match(/!rs&([0-9a-f]{2})([0-9a-f]{2})/);
        if (match) {
            const [, zoneId, strength] = match;
            setSignalStrength(prev => ({
                ...prev,
                [zoneId]: parseInt(strength, 16)
            }));
        }
    }, []);

    // Device response parser
const parseDeviceResponse = useCallback((response) => {
    if (debugMode) {
        addDebugMessage(`Parsing device response: ${JSON.stringify(response)}`, 'info');
    }
    
    if (response.type === 'initial_state') {
        setDeviceState(response.mode || 'UNKNOWN');
        setIsConnected(response.connectionStatus === 'connected');
        return;
    }

    if (response.eventInfo) {
        handleEventInfo(response.eventInfo);
        return;
    }

    if (response.deviceInfo) {
        handleNewDevice(response.deviceInfo);
        return;
    }

    const data = response.data ? response.data.toString().trim() : '';
    
    if (data) {
        if (data.startsWith('!ba&')) {
            handleBatteryStatus(data);
        }
        
        if (data.startsWith('!rs&')) {
            handleSignalStrength(data);
        }
    }

    if (response.status === 'error') {
        handleError(response.error);
    }
}, [debugMode, addDebugMessage, handleEventInfo, handleNewDevice, handleBatteryStatus, handleSignalStrength, handleError]);

// WebSocket connection setup						  
const connectWebSocket = useCallback(() => {
    if (!token) {
        if (debugMode) {
            addDebugMessage('No authentication token available', 'error');
        }
        return;
    }

    try {
        // Cleanup any existing connection
        if (webSocket.current) {
            webSocket.current.close();
            webSocket.current = null;
        }

        // Create WebSocket URL - simplified version
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        // Remove any additional path segments and keep it simple
        const wsUrl = `${protocol}//${CONFIG.WS_HOST}:${CONFIG.WS_PORT}/?token=${encodeURIComponent(token)}`;

        if (debugMode) {
            addDebugMessage(`Connecting to: ${wsUrl.split('?')[0]}`, 'info');
        }

        // Create a basic WebSocket connection without any protocols
        const ws = new WebSocket(wsUrl);

        // Add a longer timeout for connection
        const connectionTimeout = setTimeout(() => {
            if (ws.readyState !== WebSocket.OPEN) {
                ws.close();
                setError('Connection timeout');
                if (debugMode) {
                    addDebugMessage('Connection timeout', 'error');
                }
            }
        }, 15000); // 15 seconds timeout

        ws.onopen = () => {
            clearTimeout(connectionTimeout);
            if (debugMode) {
                addDebugMessage('WebSocket connected', 'info');
            }
            setIsConnected(true);
            setError(null);
            retryCount.current = 0;
        };

        ws.onclose = (event) => {
            clearTimeout(connectionTimeout);
            setIsConnected(false);
            if (debugMode) {
                addDebugMessage(`Connection closed. Code: ${event.code}, Reason: ${event.reason}`, 'error');
            }

            // Only retry if not a normal closure and we haven't exceeded max retries
            if (event.code !== 1000 && retryCount.current < 5) {
                const backoffTime = Math.min(1000 * Math.pow(2, retryCount.current), 30000);
                retryCount.current++;
                
                if (debugMode) {
                    addDebugMessage(`Retrying in ${backoffTime}ms (Attempt ${retryCount.current})`, 'info');
                }

                setTimeout(connectWebSocket, backoffTime);
            }
        };

        ws.onerror = (event) => {
            if (debugMode) {
                addDebugMessage('WebSocket error occurred', 'error');
            }
        };

        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (debugMode) {
                    addDebugMessage(`Received: ${JSON.stringify(data)}`, 'info');
                }
                parseDeviceResponse(data);
            } catch (error) {
                if (debugMode) {
                    addDebugMessage(`Message parse error: ${error.message}`, 'error');
                }
            }
        };

        webSocket.current = ws;

    } catch (error) {
        if (debugMode) {
            addDebugMessage(`Setup error: ${error.message}`, 'error');
        }
        setError(`Connection failed: ${error.message}`);
    }
}, [token, debugMode, addDebugMessage]);

// Separate function for sending a command directly
const sendWebSocketCommand = useCallback((command) => {
    if (webSocket.current?.readyState === WebSocket.OPEN) {
        const message = JSON.stringify({
            type: 'command',
            command: command,
            timestamp: new Date().toISOString()
        });

        webSocket.current.send(message);
        
        if (debugMode) {
            addDebugMessage(`Sent command: ${command}`, 'info');
        }
    } else {
        setError('WebSocket is not connected');
        if (debugMode) {
            addDebugMessage('Failed to send command - WebSocket not connected', 'error');
        }
    }
}, [debugMode, addDebugMessage]);

// Update your command queue processing
const processCommandQueue = useCallback(() => {
    if (commandQueue.length > 0 && !isProcessingCommand && webSocket.current?.readyState === WebSocket.OPEN) {
        setIsProcessingCommand(true);
        const command = commandQueue[0];
        
        if (debugMode) {
            addDebugMessage(`Processing command: ${command}`, 'info');
        }
        
        sendWebSocketCommand(command);
        
        setTimeout(() => {
            setCommandQueue(prev => prev.slice(1));
            setIsProcessingCommand(false);
        }, 5000);
    }
}, [commandQueue, isProcessingCommand, debugMode, addDebugMessage, sendWebSocketCommand]);

	// Effects
useEffect(() => {
    if (!loading && token) {
        console.log('Connecting with token available:', !!token);
        console.log('WS Config:', {
            host: CONFIG.WS_HOST,
            port: CONFIG.WS_PORT,
            secure: window.location.protocol === 'https:'
        });
        connectWebSocket();
    }
    return () => {
        if (webSocket.current) {
            console.log('Cleaning up WebSocket connection');
            webSocket.current.close(1000, "Cleanup");
        }
    };
}, [loading, token, connectWebSocket]);

    useEffect(() => {
        if (isConnected) {
            const intervalId = setInterval(() => {
                processCommandQueue();
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [isConnected, processCommandQueue]);

    // Early return for loading state
    if (loading) {
        return (
            <div className="min-h-screen bg-gray-100 flex items-center justify-center">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
                    <p className="mt-2 text-gray-600">Loading...</p>
                </div>
            </div>
        );
    }

    // Early return for unauthenticated users
    if (!authStateUser) {
        navigate('/auth');
        return null;
    }

    const handleDebugCommand = (e) => {
        e.preventDefault();
        if (debugCommand.trim()) {
            addDebugMessage(`Manual command: ${debugCommand}`, 'info');
            queueCommand(debugCommand.trim());
            setDebugCommand('');
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/auth');
        } catch (error) {
            console.error('Logout error:', error);
            setError('Failed to log out');
        }
    };
	
    return (
        <div className="min-h-screen bg-gray-100">
            {/* Header */}
            <nav className="bg-blue-600 text-white">
                <div className="container mx-auto px-4 py-3">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                            <Button variant="ghost" onClick={() => setIsSidebarOpen(true)} className="text-white hover:bg-blue-700">
                                <MenuIcon className="h-4 w-4" />
                            </Button>
                            <h1 className="text-xl font-semibold">{t.title}</h1>
                            <Button variant="ghost" onClick={() => setLanguage(l => l === 'de' ? 'en' : 'de')} className="text-white hover:bg-blue-700">
                                <Globe className="h-4 w-4 mr-2" />
                                {language === 'de' ? 'EN' : 'DE'}
                            </Button>
                        </div>
                        <div className="flex items-center space-x-4">
                            <span className={`text-sm px-2 py-1 rounded ${isConnected ? 'bg-green-500' : 'bg-red-500'}`}>
                                {isConnected ? t.connection.connected : t.connection.disconnected}
                            </span>
                            <Button 
                                variant="ghost" 
                                className="text-white hover:bg-blue-700" 
                                disabled={isProcessingCommand}
                            >
                                <RefreshCcw className={`h-4 w-4 ${isProcessingCommand ? 'animate-spin' : ''}`} />
                            </Button>
                            <span className="text-sm">
                                {authStateUser?.email}
                            </span>
                            <Button
                                variant="ghost"
                                className="text-white hover:bg-blue-700"
                                onClick={handleLogout}
                            >
                                <LogOut className="h-4 w-4 mr-2" />
                                {t.auth.logout}
                            </Button>
                        </div>
                    </div>
                </div>
            </nav>

            <Sidebar 
                isOpen={isSidebarOpen} 
                onClose={() => setIsSidebarOpen(false)}
                language={language}
                t={t}
            />

            {/* Error Notifications */}
            {error && (
                <div className="container mx-auto px-4">
                    <Alert variant="destructive" className="mt-2">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{error}</AlertDescription>
                        <Button variant="ghost" size="sm" onClick={() => setError(null)} className="absolute top-2 right-2">
                            <AlertCircle className="h-4 w-4" />
                        </Button>
                    </Alert>
                </div>
            )}

            {/* System Status Bar */}
            <div className="bg-blue-600 text-white">
                <div className="container mx-auto px-4 py-3">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                            <span className="font-semibold">
                                {deviceState !== 'UNKNOWN' 
                                    ? t.status[systemStatus]
                                    : 'Mode: --'
                                }
                            </span>
                        </div>
                        <div className="flex space-x-2">
                            <Button 
                                variant="outline" 
                                size="sm" 
                                className="bg-red-50 text-red-600 border-red-200 hover:bg-red-100 disabled:opacity-50"
                                onClick={() => queueCommand(SYSTEM_COMMANDS.ARM)}
                                disabled={isProcessingCommand || deviceState === 'ARMED'}
                            >
                                <Shield className="h-4 w-4 mr-2" />
                                {t.status.armed}
                            </Button>
                            <Button 
                                variant="outline" 
                                size="sm" 
                                className="bg-yellow-50 text-yellow-600 border-yellow-200 hover:bg-yellow-100 disabled:opacity-50"
                                onClick={() => queueCommand(SYSTEM_COMMANDS.PARTIAL_ARM)}
                                disabled={isProcessingCommand || deviceState === 'PARTIAL'}
                            >
                                <Home className="h-4 w-4 mr-2" />
                                {t.status.partiallyArmed}
                            </Button>
                            <Button 
                                variant="outline" 
                                size="sm" 
                                className="bg-green-50 text-green-600 border-green-200 hover:bg-green-100 disabled:opacity-50"
                                onClick={() => queueCommand(SYSTEM_COMMANDS.DISARM)}
                                disabled={isProcessingCommand || deviceState === 'DISARMED'}
                            >
                                <Lock className="h-4 w-4 mr-2" />
                                {t.status.disarmed}
                            </Button>
                            <Button 
                                variant="outline" 
                                size="sm" 
                                className="bg-blue-50 text-blue-600 border-blue-200 hover:bg-blue-100 disabled:opacity-50"
                                onClick={() => queueCommand(SYSTEM_COMMANDS.CLEAR_STATUS)}
                                disabled={isProcessingCommand}
                            >
                                <RotateCcw className="h-4 w-4 mr-2" />
                                {t.status.clear}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <main className="container mx-auto px-4 py-4">
                {/* Device Registration Section */}
                <Card className="mb-4">
                    <CardHeader>
                        <CardTitle>Device Management</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <DeviceRegistration 
                            onDeviceRegistered={(device) => {
                                console.log('New device registered:', device);
                            }}
                        />
                    </CardContent>
                </Card>

                {/* Events Section */}
                <Card className="mb-4">
                    <CardHeader>
                        <CardTitle>{t.events.title}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-2">
                            {events.slice(0, 5).map(event => (
                                <Alert 
                                    key={event.id} 
                                    variant={event.eventCode.startsWith('11') ? 'destructive' : 'default'}
                                >
                                    <AlertTitle>{event.eventDescription}</AlertTitle>
                                    <AlertDescription>
                                        Zone {event.zoneNumber} - {new Date(event.timestamp).toLocaleTimeString()}
                                    </AlertDescription>
                                </Alert>
                            ))}
                        </div>
                    </CardContent>
                </Card>

                {/* Accessories List */}
                <Card>
                    <CardHeader className="flex justify-between items-center">
                        <CardTitle>{t.accessories}</CardTitle>
                        <div className="flex space-x-2">
                            <Button variant="outline" size="sm">
                                <Plus className="h-4 w-4 mr-2" />
                                {t.addAccessory}
                            </Button>
                            <Button variant="outline" size="sm">
                                <Settings className="h-4 w-4" />
                            </Button>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="divide-y">
                            {accessories.map((accessory) => (
                                <div key={accessory.id} className="p-4 hover:bg-gray-50">
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center space-x-4">
                                            <span className="text-gray-500">{accessory.id}</span>
                                            <span className="font-medium">{accessory.name}</span>
                                            <span className="text-sm text-gray-500">
                                                ({t.devices.types[accessory.type.toLowerCase()]})
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-6">
                                            {getSignalStrengthDots(signalStrength[accessory.id] || accessory.signalStrength)}
                                            <Battery className={`h-4 w-4 ${
                                                batteryStatus[accessory.id] > 80 ? 'text-green-500' :
                                                batteryStatus[accessory.id] > 20 ? 'text-yellow-500' :
                                                'text-red-500'
                                            }`} />
                                            {accessory.isHome && (
                                                <Home className="h-4 w-4 text-gray-400" />
                                            )}
                                            <span className={`min-w-20 ${getStatusColor(accessory.status)}`}>
                                                {accessory.status}
                                            </span>
                                            <span className="text-sm text-gray-500">
                                                {new Date(accessory.timestamp).toLocaleTimeString()}
                                            </span>
                                            <div className="flex space-x-2">
                                                <Button variant="ghost" size="sm" onClick={() => queueCommand(`!ba&${accessory.id}`)}>
                                                    <Battery className="h-4 w-4" />
                                                </Button>
                                                <Button variant="ghost" size="sm" onClick={() => queueCommand(`!rs&${accessory.id}`)}>
                                                    <Signal className="h-4 w-4" />
                                                </Button>
                                                <Button variant="ghost" size="sm">
                                                    <Settings className="h-4 w-4" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>
            </main>

            {/* Debug Panel */}
            {debugMode && (
                <Card className="fixed bottom-0 left-0 right-0 m-4 bg-white shadow-lg">
                    <CardContent className="p-4">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle>{t.debug.title}</CardTitle>
                            <div className="space-x-2">
                                <Button 
                                    variant="outline" 
                                    size="sm"
                                    onClick={() => setDebugMessages([])}
                                >
                                    <Trash className="h-4 w-4 mr-2" />
                                    {t.debug.clear}
                                </Button>
                            </div>
                        </div>
                        
                        <form onSubmit={handleDebugCommand} className="mb-2">
                            <div className="flex space-x-2">
                                <input
                                    type="text"
                                    value={debugCommand}
                                    onChange={(e) => setDebugCommand(e.target.value)}
                                    placeholder="Enter command"
                                    className="flex-1 px-3 py-2 border rounded-md font-mono text-sm"
                                />
                                <Button type="submit" size="sm" disabled={isProcessingCommand}>
                                    <SendHorizontal className="h-4 w-4 mr-2" />
                                    Send
                                </Button>
                            </div>
                        </form>

                        <div className="h-60 overflow-y-auto border rounded p-2 bg-gray-50 font-mono text-sm">
                            {debugMessages.map((msg, index) => (
                                <div 
                                    key={index}
                                    className={`py-1 ${
                                        msg.type === 'error' ? 'text-red-600' :
                                        msg.type === 'warning' ? 'text-yellow-600' :
                                        'text-gray-700'
                                    }`}
                                >
                                    <span className="text-gray-400">
                                        {new Date(msg.timestamp).toLocaleTimeString()}
                                    </span>
                                    {' '}
                                    {msg.message}
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>
            )}

            {/* Debug Toggle Button */}
            <Button
                variant="outline"
                size="sm"
                onClick={() => setDebugMode(!debugMode)}
                className="fixed bottom-4 right-4"
            >
                <Bug className="h-4 w-4 mr-2" />
                {debugMode ? t.debug.hide : t.debug.show}
            </Button>

            {/* Additional Floating Controls */}
            <div className="fixed bottom-4 left-4 space-x-2">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                        queueCommand(SYSTEM_COMMANDS.GET_ZONES);
                        queueCommand(SYSTEM_COMMANDS.GET_BATTERY);
                        queueCommand(SYSTEM_COMMANDS.GET_SIGNAL);
                    }}
                    disabled={isProcessingCommand}
                >
                    <RefreshCcw className={`h-4 w-4 mr-2 ${isProcessingCommand ? 'animate-spin' : ''}`} />
                    Refresh All
                </Button>
                {events.length > 0 && (
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setEvents([])}
                    >
                        <BellOff className="h-4 w-4 mr-2" />
                        Clear Events
                    </Button>
                )}
            </div>

            {/* Quick Actions Menu */}
            <div className="fixed top-20 right-4 space-y-2">
                {isConnected && deviceState !== 'UNKNOWN' && (
                    <>
                        <Button
                            variant="outline"
                            size="sm"
                            className="w-12 h-12 rounded-full bg-white"
                            onClick={() => queueCommand(SYSTEM_COMMANDS.ARM)}
                            disabled={isProcessingCommand || deviceState === 'ARMED'}
                        >
                            <Shield className="h-6 w-6 text-red-600" />
                        </Button>
						<Button
                                variant="outline"
                                size="sm"
                                className="w-12 h-12 rounded-full bg-white"
                                onClick={() => queueCommand(SYSTEM_COMMANDS.PARTIAL_ARM)}
                                disabled={isProcessingCommand || deviceState === 'PARTIAL'}
                            >
                                <Home className="h-6 w-6 text-yellow-600" />
                            </Button>
                            <Button
                                variant="outline"
                                size="sm"
                                className="w-12 h-12 rounded-full bg-white"
                                onClick={() => queueCommand(SYSTEM_COMMANDS.DISARM)}
                                disabled={isProcessingCommand || deviceState === 'DISARMED'}
                            >
                                <Lock className="h-6 w-6 text-green-600" />
                            </Button>
                        </>
                    )}
                </div>
            </div>
        );
    };

    export default Dashboard;
