import React, { useState, useEffect, useRef } from 'react';
import { X, Home, Shield, Settings, CreditCard, Check } from 'lucide-react';
import { Button } from './ui/button';

const SUBSCRIPTION_PLANS = [
  {
    name: 'Basic',
    price: '60.00',
    period: 'year',
    features: [
      'Up to 5 Devices',
      'Basic Event History',
      'Email Support',
      'Standard Response Time',
      'Basic Analytics'
    ],
    planId: 'P-4XK83674NC428872CM4SAH6I'
  },
  {
    name: 'Premium',
    price: '200.00',
    period: 'year',
    features: [
      'Unlimited Devices',
      'Extended Event History',
      'Priority Support 24/7',
      'Advanced Analytics',
      'Custom Alerts',
      'API Access'
    ],
    planId: 'P-02D30781T85681127M4SAJPA'
  }
];

const PayPalButton = ({ planId }) => {
  const [loading, setLoading] = useState(true);
  const buttonRef = useRef(null);
  const buttonRendered = useRef(false);

  useEffect(() => {
    const loadAndRenderButton = async () => {
      if (!buttonRef.current || buttonRendered.current) return;

      if (!window.paypal) {
        const script = document.createElement('script');
        script.src = 'https://www.paypal.com/sdk/js?client-id=AVXY5_7oQ1S24mOLMSmpFebQ9n-kLRs2SNYF3r8yZRw7Bhb4R6yMSFSP6-b8KPLGMJrTiAOPT3wSqGpr&vault=true&intent=subscription';
        script.async = true;
        
        script.onload = () => {
          renderPayPalButton();
        };
        
        document.body.appendChild(script);
      } else {
        renderPayPalButton();
      }
    };

    const renderPayPalButton = () => {
      if (buttonRendered.current) return;

      try {
        window.paypal.Buttons({
          style: {
            label: 'subscribe',
            shape: 'rect',
            layout: 'vertical'
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: planId
            });
          },
          onApprove: (data) => {
            console.log('Subscription successful:', data.subscriptionID);
            alert('Subscription successful! ID: ' + data.subscriptionID);
          },
          onError: (err) => {
            console.error('PayPal Button Error:', err);
          }
        }).render(buttonRef.current);
        
        buttonRendered.current = true;
        setLoading(false);
      } catch (error) {
        console.error('Error rendering PayPal button:', error);
        setLoading(false);
      }
    };

    loadAndRenderButton();
  }, [planId]);

  return (
    <div className="min-h-[100px] relative">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary"></div>
        </div>
      )}
      <div ref={buttonRef} className="paypal-button-container" />
    </div>
  );
};

const Sidebar = ({ isOpen, onClose, language, t }) => {
  const [activePlan, setActivePlan] = useState(null);

  return (
    <div className={`fixed inset-y-0 right-0 w-64 bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-20`}>
      <div className="flex flex-col h-full">
        <div className="p-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold">{t.menu}</h2>
            <Button variant="ghost" onClick={onClose}>
              <X size={24} />
            </Button>
          </div>
          
          <nav>
            <ul className="space-y-2">
              <li>
                <Button variant="ghost" className="w-full justify-start text-left">
                  <Home className="mr-2" size={20} />
                  {t.dashboard}
                </Button>
              </li>
              <li>
                <Button variant="ghost" className="w-full justify-start text-left">
                  <Shield className="mr-2" size={20} />
                  {t.security}
                </Button>
              </li>
              <li>
                <Button variant="ghost" className="w-full justify-start text-left">
                  <Settings className="mr-2" size={20} />
                  {t.settings}
                </Button>
              </li>
            </ul>
          </nav>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-200 my-2"></div>

        {/* Subscription Section */}
        <div className="flex-1 overflow-y-auto p-4">
          <div className="mb-4">
            <h2 className="text-lg font-semibold flex items-center">
              <CreditCard className="mr-2" size={20} />
              Abonnieren
            </h2>
          </div>

          <div className="space-y-4">
            {SUBSCRIPTION_PLANS.map((plan) => (
              <div 
                key={plan.name}
                className="bg-white rounded-lg shadow border border-gray-200 overflow-hidden"
              >
                <div 
                  className="p-4 cursor-pointer hover:bg-gray-50"
                  onClick={() => setActivePlan(activePlan === plan.name ? null : plan.name)}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-semibold">{plan.name}</h3>
                      <p className="text-sm text-gray-600">
                        ${plan.price}/{plan.period}
                      </p>
                    </div>
                    {plan.name === 'Premium' && (
                      <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
                        Recommended
                      </span>
                    )}
                  </div>

                  {activePlan === plan.name && (
                    <div className="mt-4">
                      <ul className="space-y-2 mb-4">
                        {plan.features.map((feature) => (
                          <li key={feature} className="flex items-center text-sm text-gray-600">
                            <Check className="h-4 w-4 text-green-500 mr-2" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                      <PayPalButton planId={plan.planId} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;