import React from 'react';

export const Button = ({ children, className, variant = 'default', ...props }) => {
  const baseStyle = 'px-3 py-2 sm:px-4 sm:py-2 rounded font-semibold transition-colors duration-200 text-sm sm:text-base';
  const variantStyles = {
    default: 'bg-blue-600 text-white hover:bg-blue-700',
    ghost: 'bg-transparent hover:bg-gray-100',
    // Add more variants as needed
  };

  return (
    <button 
      className={`${baseStyle} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};